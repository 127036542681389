<template>
  <v-container>
    <PhotoUpload />
    <v-data-table
      :headers="headers[$vuetify.breakpoint.xsOnly]"
      :items="products"
      no-data-text="No hay productos registrados"
      :items-per-page="-1"
      item-key="account,code"
      mobile-breakpoint="0"
      :search="search"
      sort-by="category,product_name"
      class="table-cursor mt-3"
      @click:row="handleClick"
      fixed-header
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-select
              hide-details="auto"
              v-model="sSelected"
              label="Categoría"
              :items="categories"
              outlined
              item-text="name"
              item-value="code"
              required
              @change="CategoryChange"
              return-object
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn fab dark small color="success" @click="newProduct">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer></v-spacer>
      </template>

      <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !== ''">
        <v-img
          contain
          width="48"
          :src="getImagePhoto(item.account, item.store, item.picture)"
          v-if="item.picture"
        ></v-img>
      </template>
      <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
        <b>{{ item.product_name }}</b>
        <span v-if="$vuetify.breakpoint.xsOnly">
          <small> ({{ item.category }})</small><br />
          <small> {{ item.size }} {{ item.uom }} </small>
        </span>
      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ item.size + " " + item.uom }} <br />
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        {{ "$ " + parseFloat(item.cost).toLocaleString("es") }}
      </template>
      <template v-slot:[`item.tax_base`]="{ item }">
        {{ "$ " + parseFloat(item.tax_base).toLocaleString("es") }}
      </template>
      <template v-slot:[`item.tax_value`]="{ item }">
        {{ "$ " + parseFloat(item.tax_value).toLocaleString("es") }}
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ "$ " + parseFloat(item.price).toLocaleString("es") }}
      </template>

      <template v-slot:[`item.cost_uom`]="{ item }">
        {{ "$ " + parseFloat(item.cost_uom).toLocaleString("es") }}
      </template>
      <template v-slot:[`item.price_uom`]="{ item }">
        {{ "$ " + parseFloat(item.price_uom).toLocaleString("es") }}
      </template>
    </v-data-table>

    <ProductForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      :categories="categories"
      :catalog="byCategories"
      :store="store"
      @close="dialog = false"
      :subcategories="subcategories"
      :brands="brands"
      :suppliers="this.suppliers"
      @refresh="refresh()"
    />

    <template v-if="sSelected === ''">
      <v-layout align-center justify-center>
        <v-flex xs12 sm10 md10>
          <v-row class="toptop">
            <v-col
              v-for="catego in categories"
              :key="catego"
              cols="6"
              md="3"
              align="center"
              @click="CategoryChange(catego)"
              justify="center"
              align-self="center"
            >
              <v-avatar size="120" class="categories">
                <v-img contain :src="getbgpic(catego)" /> </v-avatar
              ><br />
              <span>{{ catego }}</span>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </template>
    <v-sheet flat class="mt-3" v-else>
      <v-navigation-drawer
        temporary
        right
        class="navigation-drawer"
        width="480"
        :value="dialog_smart"
        app
      >
        <v-toolbar>
          <v-toolbar-title>Lista maestra</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="dialog_smart = !dialog_smart">mdi-close</v-icon>
        </v-toolbar>
        <v-card height="100vh">
          <v-card-text color="transparent" class="scroll pa-0">
            <v-expansion-panels accordion flat>
              <v-expansion-panel v-for="(item, i) in smart_ctgry" :key="i">
                <v-card tile flat>
                  <v-expansion-panel-header>
                    <v-list-item>
                      <v-list-item-title v-text="i"></v-list-item-title>
                    </v-list-item>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list dense>
                      <v-list-item
                        v-for="(child, index) in smart_ctgry[i]"
                        :key="index"
                        @click="add_to_supplier(child)"
                        class="ma-0 pa-0"
                      >
                        <v-list-item-avatar size="64">
                          <v-img
                            cover
                            :src="
                              getImagePhoto(child.account, child.supplier, child.picture)
                            "
                            v-if="child.picture"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="child.product_name" />
                          <v-list-item-subtitle>
                            {{ child.size + " " + child.uom }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{ "$ " + parseFloat(child.price).toLocaleString() }}
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-expansion-panel-content>
                </v-card>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions class="fixed-footer"> </v-card-actions>
        </v-card>

        <v-bottom-navigation app>
          <v-row justify="center" class="pt-2">
            <v-col md="12" cols="12">
              <v-text-field
                v-model="search"
                hide-details
                append-icon="mdi-magnify"
                outlined
                rounded
                dense
                placeholder="Buscar producto ..."
                @keypress="update_selected()"
              />
            </v-col>
          </v-row>
        </v-bottom-navigation>
      </v-navigation-drawer>

      <v-dialog v-model="loading_status" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando ...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-sheet>
    <v-bottom-navigation app v-if="$vuetify.breakpoint.xsOnly">
      <span class="pt-2">
        <v-select
          hide-details="auto"
          v-model="sSelected"
          label="Categoría"
          :items="categories"
          outlined
          item-text="name"
          item-value="code"
          required
          dense
          @change="CategoryChange"
          return-object
        />
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          hide-details
          append-icon="mdi-magnify"
          outlined
          rounded
          dense
          placeholder="Buscar producto ..."
        />
      </span>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import ProductForm from "../../components/RawMaterialForm.vue";
import PhotoUpload from "../../components/PhotoUpload.vue";
import createStore from "../../utils/createStore.js";

export default {
  components: { ProductForm, PhotoUpload },
  data() {
    return {
      products: [],
      search_smart: "",
      dialog_menu: false,
      s_expand: true,
      smart_width: 800,
      dialog_smart: false,
      smart_categories: [],
      smart_lst_selected: [],
      header_size: true,
      table: "raw_material",
      search: "",
      search_supplier: "",
      dialog_suppliers: true,
      suppliers: [],
      errors: [],
      store: "",
      storesLst: [],
      valid: false,
      dialog: false,
      newItem: false,
      loading_status: false,
      byCategories: [],
      categories: [],
      CategorySelected: [],
      subcategories: [],
      brands: [],
      supplier_dialog: false,
      supplier_selected: createStore(),
      headers: {
        false: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
            groupable: false,
          },
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "category",
            dataType: "text",
            groupable: true,
          },
          {
            text: "Sub-Categoría",
            align: "start",
            sortable: true,
            value: "subcategory",
            dataType: "text",
            groupable: true,
          },
          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "brand",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Tamaño",
            align: "center",
            sortable: true,
            value: "size",
            dataType: "text",
            groupable: false,
          },
          {
            text: "UOM",
            align: "center",
            sortable: true,
            value: "uom",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Pack",
            align: "center",
            sortable: true,
            value: "pack",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
            groupable: false,
          },
          {
            text: " Costo x UOM",
            align: "end",
            sortable: true,
            value: "cost_uom",
            dataType: "number",
            groupable: false,
          },
          {
            text: "%",
            align: "end",
            sortable: true,
            value: "utility",
            dataType: "number",
            groupable: false,
          },
          {
            text: "Base Gravable",
            align: "end",
            sortable: true,
            value: "tax_base",
            dataType: "number",
            groupable: false,
          },
          {
            text: "Impuesto",
            align: "end",
            sortable: true,
            value: "tax",
            dataType: "number",
            groupable: false,
          },
          {
            text: "Valor Impuesto",
            align: "end",
            sortable: true,
            value: "tax_value",
            dataType: "number",
            groupable: false,
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
            groupable: false,
          },

          {
            text: "Precio x UOM",
            align: "end",
            sortable: true,
            value: "price_uom",
            dataType: "number",
            groupable: false,
          },
        ],
        true: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 25,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
          },
        ],
      },
      headers_suppliers: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          width: 40,
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          dataType: "text",
        },
      ],
      items: [],
      smart_lst: [],
      smart_ctgry: [],
      item: createItem(),
      edit: false,
      catSelected: "",
      sSelected: "",
      open: [],
    };
  },
  mounted() {
    this.storesLst = window.stores;
    this.get_suppliers();
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getbgpic(ctgry) {
      var phtslst = [];
      if (ctgry) {
        phtslst = this.byCategories[ctgry];
      } else {
        phtslst = this.byCategories;
      }
      if (phtslst.length > 0) {
        // phtslst = phtslst.filter(function (el) {
        //   return el.picture !== "";
        // });

        phtslst = phtslst.filter(function (item) {
          if (item["picture"] !== "" && item["main"] === "1") {
            return true;
          } else {
            return false;
          }
        });
      }

      if (phtslst.length > 0) {
        var rdm = phtslst[(phtslst.length * Math.random()) | 0];
        // console.log(rdm);

        if (rdm.picture) {
          return this.getImagePhoto(rdm.account, rdm.store, rdm.picture);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    updateForm(item) {
      //if (item.length > 0) console.log(item[0].name, item[0].to);
      // if (item[0].to) this.$router.push(item[0].to);
      console.log(item[0].data);
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    newProduct() {
      this.newItem = true;
      this.item = createItem();
      this.store = this.$store.state.company.code;
      this.item.account = this.$store.state.company.account;
      this.item.store = this.$store.state.company.code;
      this.item.supplier = this.supplier_selected.code;
      console.log(this.item);
      this.dialog = true;
    },
    update_selected() {
      this.CategorySelected = this.smart_lst;
    },
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    add_to_supplier(e) {
      this.item = { ...e };
      this.store = this.supplier_selected.code;
      this.item.store = this.supplier_selected.code;
      this.item.supplier = this.supplier_selected.code;
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        data: [this.item],
      };
      webserver("put_table", qry, () => {
        this.loading_status = false;
        this.get_items(this.supplier_selected);
      });
    },
    handleClick(data) {
      this.store = data.store;
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    CategoryChange(e) {
      this.CategorySelected = this.byCategories[e];
      this.sSelected = e;
    },
    get_items() {
      this.items = [];
      this.loading_status = true;
      //console.log(this.$store.state.profile);
      var qry = {
        account: this.$store.state.profile.account,
        table: "raw_material",
        filters: [
          // { field: "store", operator: "=", value: this.$store.state.profile.store },
        ],
      };
      console.log(qry);
      webserver("get_table", qry, (data) => {
        console.log(data);
        this.products = data;
        this.loading_status = false;

        // if (data.length !== 0) {

        //   var ctgry = data.reduce(function (r, a) {
        //     r[a.category] = r[a.category] || [];
        //     r[a.category].push(a);
        //     return r;
        //   }, Object.create(null));

        //   for (const [key] of Object.entries(ctgry)) {
        //     ctgry[key].sort(function (a, b) {
        //       var textA = a.product_name.toUpperCase();
        //       var textB = b.product_name.toUpperCase();
        //       return textA < textB ? -1 : textA > textB ? 1 : 0;
        //     });
        //   }
        //   this.byCategories = ctgry;
        //   this.categories = Object.keys(ctgry).sort();

        //   // for (const [key] of Object.entries(this.categories)) {
        //   this.categories.forEach((key) => {
        //     var children = [];
        //     ctgry[key].forEach((rg) => {
        //       children.push({
        //         id: rg.code,
        //         name: rg.product_name,
        //         data: rg,
        //       });
        //     });

        //     this.items.push({
        //       id: key,
        //       name: key,
        //       children: children,
        //     });
        //   });

        //   var sbctgry = data.reduce(function (r, a) {
        //     r[a.subcategory] = r[a.subcategory] || [];
        //     r[a.subcategory].push(a);
        //     return r;
        //   }, Object.create(null));
        //   this.subcategories = Object.keys(sbctgry).sort();

        //   var brnd = data.reduce(function (r, a) {
        //     r[a.brand] = r[a.brand] || [];
        //     r[a.brand].push(a);
        //     return r;
        //   }, Object.create(null));
        //   this.brands = Object.keys(brnd).sort();
        //   this.smart_lst = data;
        // }

        if (this.$vuetify.breakpoint.xsOnly) {
          this.dialog_suppliers = false;
        }
        this.CategorySelected = this.byCategories[this.sSelected];
      });
    },
    async get_suppliers() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: "suppliers",
        filters: [
          // { field: "store", operator: "=", value: this.$store.state.profile.store },
        ],
      };
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_table",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      var result = await promise;
      if (result) {
        this.suppliers = result;
      }
      console.log("Suppliers", this.suppliers);
      this.get_items();
      this.loading_status = false;
    },
    newForm() {
      this.item = createItem();
      this.store = "000000";
      this.item.account = this.$store.state.profile.account;
      this.item.store = "000000";
      this.item.supplier = "SCP";
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    refresh() {
      this.get_items();
      this.dialog = false;
    },
    getImagePhoto: function (account, code, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/" +
        src +
        "?" +
        currentdate;
      console.log(url);
      return url;
    },
    changeMenu(e) {
      this.search_smart = "";
      this.smart_lst_selected = this.smart_ctgry[e];
      //console.log(this.smart_lst_selected, this.smart_ctgry, e);
    },
  },
  watch: {
    s_expand: function (e) {
      //console.log(e);
      if (e) {
        this.smart_width = 800;
      } else {
        this.smart_width = 450;
      }
    },
  },
};
</script>

<style>
.file-input {
  display: none;
}

.toptop {
  top: 70px !important;
}

.centered-input input {
  text-align: center;
}

.table-cursor tbody tr:hover {
  cursor: pointer;
}

.v-expansion-panel-header {
  padding: 0px !important;
}
.v-card__title {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 100;
}
.v-btn__content {
  width: 100% !important;
  white-space: normal !important;
}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 70px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 2;
}
</style>
